// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.order_import_alert .el-alert__content {\n  width: 100%;\n}\n.el-message-box__wrapper .my_import_goods_msg_box {\n  position: absolute;\n  top: 20px;\n  left: 50%;\n  -webkit-transform: translateX(-50%);\n          transform: translateX(-50%);\n  width: 50%;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
