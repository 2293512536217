import {
  createInput, createNumber, createDatePicker, createSelect, formEvetns, msgBox,
} from 'nb-modules';
import {
  clone, merge, isEmpty, formatTimes, lazy,
} from 'ldx-utils';
import dayjs from 'dayjs';
import { getChildRegion } from '@/com';
import { tableInfo } from '@/utils/pageConfigs.js';

export function useAlreadyOrder() {
  this.formEvents = formEvetns(this);

  const end = Date.now();
  const start = end - 30 * 86400 * 1000;
  this.formInfo = {
    ref: 'nbForm',
    options: {
      type: 'search',
      // renderBtn: h => {
      //   return h('el-checkbox', {
      //     style: 'margin-left: 10px;',
      //     attrs: {type: 'primary', value: this.isSame},
      //     on: {
      //       input: res => this.isSame = res
      //     }
      //   }, '收货信息相同');
      // }
    },
    columns: [
      createInput('订货单号', 'supplyNo', {
        attrs: { placeholder: '请输入订货单号' },
      }),
      {
        slot: 'logisticCode', label: '物流公司', key: 'logisticCode', value: '',
      },
      createInput('运单号', 'logisticNo', {
        attrs: { placeholder: '请输入运单号' },
      }),
      createDatePicker('发货时间', 'times', {
        value: [start, end],
        attrs: { placeholder: '请选择发货时间' },
      }),
      createInput('收件人姓名', 'receiverName', {
        attrs: { placeholder: '请输入完整收件人名称' },
      }),
      createInput('收件人手机', 'mobile', {
        attrs: { placeholder: '请输入收件人手机号' },
      }),
      {
        use: 'nb-area',
        label: '地址',
        key: 'areas',
        value: [],
        attrs: {
          length: 3,
          placeholder: '请选择地址',
          request: (id) => getChildRegion.call(this, id),
        },
      },
    ],
  };

  this.tableInfo = merge(tableInfo(this), {
    config: {
      table: {
        showSelection: true,
        'handle-attrs': { width: 200 },
        selection: {
          selectable: (row) => (!!row.canModify),
        },
        events: {
          'selection-change': this.handleSelectionChange,
        },
      },
    },
    columns: [
      { prop: 'supplyOrderNo', label: '订货单号' },
      { prop: 'receiveInfo', label: '收货信息' },
      { prop: 'logisticName', label: '物流公司' },
      { prop: 'logisticNo', label: '运单号' },
      { prop: 'memo', label: '备注' },
      { label: '发货时间', render: (h, { row }) => h('div', formatTimes(dayjs, row.deliverTime)) },
    ],
  });
}

export function modifyBatchOrder() {
  this.tableInfo = merge(tableInfo(this), {
    config: {
      table: {
        showIndex: true,
        showHandle: false,
      },
      pagination: { show: false },
    },
    columns: [
      { prop: 'supplyOrderNo', label: '订货单号' },
      { prop: 'logisticName', label: '原物流公司' },
      { prop: 'logisticNo', label: '原运单号' },
      { isSlot: 'newLogisticName', header: 'header-newLogisticName', label: '新流公司' },
      { isSlot: 'newLogisticNo', header: 'header-newLogisticNo', label: '新运单号' },
      { isSlot: 'newMemo', header: 'header-newMemo', label: '备注' },
      { isSlot: 'memoImg', header: 'header-memoImg', label: '备注图片' },
    ],
  });
}

export function modifySingleOrder() {
  this.formEvents = {
    onreset: this.hide,
    onsubmit: this.onsubmit,
  };
  this.formInfo = {
    ref: 'nbForm',
    options: {
      formAttrs: { 'label-width': '80px' },
      resetButton: { type: 'default', text: '取消' },
    },
  };
}
