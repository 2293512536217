<template>
  <div class="app-container yfh-page">
    <div
      class="app-item"
      style="margin-bottom: 50px;"
    >
      <nb-form
        v-model="forms"
        v-bind="formInfo"
        v-on="formEvents"
      >
        <template #logisticCode>
          <el-select
            v-model="forms.logisticCode"
            filterable
            clearable
            placeholder="请选择物流公司"
          >
            <el-option
              v-for="item in logisticList"
              :key="item.logisticId"
              :label="item.logisticName"
              :value="item.logisticCode"
            />
          </el-select>
        </template>
      </nb-form>
    </div>
    <div class="app-item">
      <div class="tip-box">
        <el-button
          type="primary"
          size="small"
          @click="tapModifyBatch"
        >
          批量修改运单号
        </el-button>
        <el-alert
          title="订货单在发货后10天内仅可修改1次运单号，请谨慎操作"
          type="error"
          :closable="false"
        />
      </div>
      <nb-table v-bind="tableInfo">
        <template #handles="{row}">
          <el-button
            type="text"
            size="mini"
            @click="tapDetail(row)"
          >
            物流详情
          </el-button>
          <el-button
            v-if="row.canModify"
            type="text"
            size="mini"
            @click="tapModifySingle(row)"
          >
            修改运单号
          </el-button>
        </template>
      </nb-table>
    </div>

    <!-- 物流详情 -->
    <logistics-details ref="logisticsDetails" />
    <!-- 单个运单号修改 -->
    <modify-single
      ref="modifySingleRef"
      @submit="submitSingle"
    />
    <!-- 批量修改运单号 -->
    <modify-batch
      ref="modifyBatchleRef"
      @submit="submitSingle"
    />
  </div>
</template>

<script>
import modifySingle from './components/modify-single.vue';
import modifyBatch from './components/modify-batch.vue';
import logisticsDetails from './components/logistics-details.vue';
import { useAlreadyOrder } from './pageConfigs.js';

export default {
  components: { modifySingle, modifyBatch, logisticsDetails },
  data() {
    useAlreadyOrder.call(this);
    return {
      forms: {},
      isSame: false,
      logisticList: [],
      checks: [], // 表格中已选择的list
    };
  },
  mounted() {
    this.getLogisticList(); // 弹框中物流公司下拉列表数据
  },
  methods: {
    // 点击 物流详情 按钮
    tapDetail(row) {
      this.$refs.logisticsDetails.show(row);
    },
    // 点击 修改运单号 按钮
    tapModifySingle(row) {
      this.$refs.modifySingleRef.show(this.logisticList, row);
    },
    // 修改单个运单号成功
    submitSingle() {
      this.$refs.nbTable.getData();
    },
    // 批量修改运单号
    tapModifyBatch() {
      if (!this.checks.length) {
        this.$message({ type: 'error', message: '请选择运单' });
        return;
      }
      this.$refs.modifyBatchleRef.show(this.checks, this.logisticList);
    },
    handleSelectionChange(list) {
      this.checks = list;
    },
    getData(args) {
      return new Promise(async (resolve) => {
        const [province, city, district, town] = this._getAreas();
        const [shippingBeginTime, shippingEndTime] = this.forms.times || [];
        const params = {
          ...this.forms,
          ...args,
          isSameAddress: this.isSame ? 1 : 0,
          province,
          city,
          district,
          town,
          shippingBeginTime,
          shippingEndTime,
        };
        delete params.areas;
        delete params.times;
        const res = await this.$axios.post(this.$api.supplierOrderOrder.recordPage, params);
        if (res.code === 0) {
          const data = res.data || {};
          const list = data.records || [];
          resolve({ list, total: +data.total });
        } else {
          resolve({ list: [], total: 0 });
        }
      });
    },
    _getAreas() {
      const { nbForm } = this.$refs;
      const cascader = nbForm.$refs.areas[0].getCascader();
      const checks = cascader.getCheckedNodes()[0] || {};
      const labels = checks.pathLabels || [];
      return labels;
    },
    // 弹框中物流公司下拉列表数据
    async getLogisticList() {
      const res = await this.$axios.get(this.$api.commonNew.getLogisticList);
      if (res.code === 0) {
        this.logisticList = res.data || [];
      }
    },
  },
};
</script>

<style lang="scss">
.yfh-page{
  .nb-form{
    .el-select, .el-cascader{width: 100%;}
    .el-form-item__label{font-weight: bold; font-size: 12px;}
  }
  .tip-box{
    display: flex; margin-bottom: 10px;
    .el-button{margin-right: 20px;}
  }
  .el-alert{width: 400px;}
}
</style>
