var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "SearchPanel",
        {
          attrs: { flex: "", "show-btn": "", size: "mini" },
          on: { getList: _vm.onSearch },
        },
        [
          _c(
            "div",
            [
              _c("p", [_vm._v(" 订货单号 ")]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入订货单号",
                  size: "mini",
                  clearable: "",
                },
                model: {
                  value: _vm.supplyNo,
                  callback: function ($$v) {
                    _vm.supplyNo = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "supplyNo",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 商品ID ")]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入完整商品ID",
                  size: "mini",
                  clearable: "",
                  type: "number",
                },
                model: {
                  value: _vm.goodsId,
                  callback: function ($$v) {
                    _vm.goodsId = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "goodsId",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 商品名称 ")]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入关键字",
                  size: "mini",
                  clearable: "",
                },
                model: {
                  value: _vm.goodsName,
                  callback: function ($$v) {
                    _vm.goodsName = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "goodsName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 收件人名称 ")]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入完整收件人名称",
                  size: "mini",
                  clearable: "",
                },
                model: {
                  value: _vm.receiverName,
                  callback: function ($$v) {
                    _vm.receiverName =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "receiverName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 收件人手机号 ")]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入收件人手机号",
                  size: "mini",
                  clearable: "",
                },
                model: {
                  value: _vm.receiverMobile,
                  callback: function ($$v) {
                    _vm.receiverMobile =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "receiverMobile",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 下单时间 ")]),
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  size: "mini",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "timestamp",
                },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "button-right", size: "mini" },
              on: { click: _vm.resetSearch },
              slot: "button-right",
            },
            [_vm._v(" 重 置 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "button-right", size: "mini" },
              on: { click: _vm.onExport },
              slot: "button-right",
            },
            [_vm._v(" 导 出 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.tableData,
            "default-expand-all": "",
            "cell-style": {
              "background-color": "#f5f7fa",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-table",
                      {
                        staticClass: "interior-table",
                        attrs: {
                          data: row.supplyOrderGoodsVOList,
                          size: "small",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "商品信息", width: "300" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row: goods }) {
                                  return [
                                    _c("div", { staticClass: "goods-card" }, [
                                      _c("img", {
                                        attrs: {
                                          src: goods.goodsImg.split(",")[0],
                                        },
                                      }),
                                      _c("div", { staticClass: "content" }, [
                                        _c("p", [
                                          _vm._v(_vm._s(goods.goodsName)),
                                        ]),
                                        _c("p", { staticClass: "sku" }, [
                                          _vm._v(
                                            " ID:" + _vm._s(goods.goodsId) + " "
                                          ),
                                        ]),
                                        _c("p", { staticClass: "sku" }, [
                                          _vm._v(
                                            " 规格：" +
                                              _vm._s(goods.skuName) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { label: "订货价", prop: "purchasePrice" },
                        }),
                        _c("el-table-column", {
                          attrs: { label: "数量", prop: "purchaseCount" },
                        }),
                        _c("el-table-column", {
                          attrs: { label: "商品金额", prop: "purchaseAmount" },
                        }),
                        _c("el-table-column", {
                          attrs: { label: "状态", prop: "statusExplain" },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "售后状态",
                            prop: "refundStatusExplain",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "订货单号", prop: "supplyNo" },
          }),
          _c("el-table-column", {
            attrs: { label: "订货单状态", prop: "statusExplain" },
          }),
          _c("el-table-column", {
            attrs: { label: "订单金额", prop: "purchaseAmount" },
          }),
          _c("el-table-column", {
            attrs: { label: "收件人", prop: "receiverName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [_vm._v(_vm._s(row.receiverName))]),
                    _vm._v("  "),
                    _c("span", [_vm._v(_vm._s(row.receiverMobile))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "下单时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.formatTime(row.time)))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "结算状态", prop: "settlementStatusExplain" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.toDetail(row)
                          },
                        },
                      },
                      [_vm._v(" 查看详情 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: !row?.canDeliver,
                          size: "mini",
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onDeliverGoods(row)
                          },
                        },
                      },
                      [_vm._v(" 发货 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.currentPage,
          "page-sizes": [10, 20, 30, 50],
          "page-size": _vm.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.count,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("DeliverOrderDialog", {
        attrs: { id: _vm.id, type: "add" },
        on: { success: _vm.deliverSuccess },
        model: {
          value: _vm.showDeliverModel,
          callback: function ($$v) {
            _vm.showDeliverModel = $$v
          },
          expression: "showDeliverModel",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }