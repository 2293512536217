<template>
  <el-dialog
    title="发货"
    :visible.sync="twoModelShow"
    width="1000px"
    :before-close="handleClose"
    @open="open"
  >
    <div class="deliver_order_dialog">
      <div
        v-if="orderData"
        class="header"
      >
        <div class="order_number">
          <span style="margin-right: 40px">订货单号: {{ orderData.supplyNo }}</span>
        </div>
        <el-button
          v-if="type === 'add'"
          type="primary"
          size="small"
          @click="operationWaybillOrder('add')"
        >
          添加运单
        </el-button>
      </div>
      <el-divider />
      <div
        v-for="(item, i) in dataList"
        :key="i"
        class="waybill"
      >
        <el-row
          :gutter="20"
          type="flex"
          :align="'top'"
        >
          <el-col :span="2">
            运单{{ i + 1 }}:
          </el-col>
          <el-col :span="22">
            <div class="waybill_header">
              <div class="waybill_header_box">
                <p>
                  <span class="red">*</span>
                  运单号：
                </p>
                <el-input
                  v-if="type === 'add' && orderData.shippingType === 1"
                  v-model.trim="item.logisticNo"
                  placeholder="请输入运单号"
                  size="small"
                  :disabled="type === 'edit'"
                  @input="debounceGetLogisticFn(item)"
                />
                <span v-if="type === 'add' && orderData.shippingType === 2">无</span>
                <span v-if="type === 'edit'">{{ item.logisticNo }}</span>
              </div>
              <div class="waybill_header_box">
                <p>
                  <span class="red">*</span>
                  物流公司：
                </p>
                <el-select
                  v-if="type === 'add'"
                  v-model="item.logisticName"
                  placeholder="请选择物流公司"
                  size="small"
                  :disabled="type === 'edit'"
                >
                  <el-option
                    v-for="(value, index) in logisticsCompanys"
                    :key="index"
                    :label="value.logisticName"
                    :value="value.logisticName"
                  />
                </el-select>
                <span v-if="type === 'edit'">{{ item.logisticName }}</span>
              </div>
              <div class="waybill_header_box">
                <p>备注</p>
                <el-input
                  v-model="item.memo"
                  type="textarea"
                  :rows="4"
                  placeholder="备注"
                  size="small"
                  maxlength="350"
                  show-word-limit
                />
              </div>
              <div style="margin-left: 70px;">
                <UpImageNew
                  :image-list.sync="item.memoImg"
                  type="string"
                  :num="5"
                />
                <div>
                  建议：800px*800px，2M以内；图片可拖动排序，限5张。
                </div>
              </div>
              <el-link
                v-if="type === 'add' && dataList.length > 1"
                type="danger"
                :underline="false"
              >
                <i
                  class="el-icon-delete"
                  @click="operationWaybillOrder('delete', i)"
                ></i>
              </el-link>
            </div>

            <div class="waybill_goods_select">
              <el-checkbox
                v-model="item.relateGoods"
                :true-label="1"
                :false-label="0"
                @change="(e)=>{changeRelateGoods(e,item)}"
              >
                关联商品
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="整单发货时，无需关联商品；多运单发货时，必须关联商品"
                  placement="right"
                >
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-checkbox>
            </div>

            <div v-if="item.relateGoods">
              <div
                v-for="(value,itemIndex) in item.packageRelateGoodsDetailList"
                v-show="value.isDelete !== 1"
                :key="itemIndex"
                class="waybill_header waybill_goods_box flex"
              >
                <div class="waybill_header_box">
                  <p><span class="red">*</span>商品</p>
                  <el-select
                    v-model="value.supplyOrderGoodsId"
                    popper-class="waybill_goods_select"
                    placeholder="请选择商品"
                    size="small"
                    @change="changeGoods(value, i)"
                  >
                    <el-option
                      v-for="(goodsitem, index) in goodsList"
                      :key="index"
                      :label="goodsitem.goodsName"
                      :value="goodsitem.supplyOrderGoodsId"
                      :disabled="disabledGoodsFn(goodsitem, item)"
                    >
                      <div>
                        <span class="wd-float-left f14">
                          {{ goodsitem.goodsName }}（{{ goodsitem.skuName }}）
                        </span>
                        <span
                          class="wd-float-left f12 margin-t20"
                          style="display: block;position:absolute;"
                        >
                          已发 {{ goodsitem.shipmentCount }} / 下单 {{ goodsitem.num }}
                        </span>
                      </div>
                    </el-option>
                  </el-select>
                </div>
                <div class="waybill_header_box">
                  <p><span class="red">*</span>数量</p>
                  <div>
                    <el-input-number
                      v-model="value.shipmentCount"
                      controls-position="right"
                      :min="0"
                      size="small"
                      @change="changeGoodsNum(value, i)"
                    />
                    <div
                      v-if="value.shipmentCount === 0"
                      class="red"
                      style="font-size: 10px; line-height: 18px"
                    >
                      请输入商品数量
                    </div>
                  </div>
                </div>
                <el-link
                  v-if="item.packageRelateGoodsDetailList
                    && item.packageRelateGoodsDetailList.length > 1"
                  :underline="false"
                  type="danger"
                >
                  <i
                    class="el-icon-delete"
                    @click="deleteGoods(itemIndex, item)"
                  ></i>
                </el-link>
              </div>

              <el-button
                style="margin-top: 10px"
                type="primary"
                size="small"
                @click="addGoods(item)"
              >
                添加商品
              </el-button>
            </div>
          </el-col>
        </el-row>
        <el-divider />
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="handleClose"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        :loading="btnLoading"
        @click="submit"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import UpImageNew from '@/components/common/upImageNew/index';
import { debounce } from '@/components/common/common';

export default {
  name: '',
  components: { UpImageNew },
  model: {
    prop: 'twoModelShow',
    event: 'changeTwoModelShow',
  },
  props: {
    twoModelShow: Boolean,
    id: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'edit',
    },
  },
  data() {
    return {
      orderData: {},
      dialogVisible: false,
      dataList: [],
      logisticsCompanys: [],
      goodsList: [],
      btnLoading: false,
      debounceGetLogisticFn: undefined,
    };
  },
  mounted() {
    this.debounceGetLogisticFn = debounce(this.getLogisticData);
  },
  methods: {
    // 获取快递数据
    getLogisticData(row) {
      (async () => {
        const res = await this.$axios.get(this.$api.deliver_center.download_logisticByNo, {
          params: {
            logisticNo: row.logisticNo,
          },
        });
        if (res.code !== 0 || !res.data) {
          row.logisticName = '';
          return;
        }
        row.logisticName = res.data.logisticName;
      })();
    },
    handleClose() {
      this.$emit('changeTwoModelShow', false);
      this.dataList = [];
    },
    async open() {
      // 获取订货单详情
      let { code, data } = await this.getDetail(this.id);
      if (code !== 0) return;
      this.orderData = data;
      if (this.type === 'edit') {
        // 获取导入详情
        let { code, data } = await this.getImportDetail(this.orderData.supplyNo);
        if (code !== 0) return;
        this.dataList = data;
      } else if (this.type === 'add') {
        // 获取快递列表
        this.getLogisticList();
        // 添加初始表单
        this.operationWaybillOrder('add');
      }
      // 获取商品列表
      {
        const { code, data } = await this.getGoodsList(this.orderData.supplyNo);
        if (code !== 0) return;
        this.goodsList = data;
      }
    },
    // 详情
    getDetail(id) {
      return this.$axios.get(this.$api.supplierOrderOrder.detail, { params: { id } });
    },
    // 获取导入订单详情
    getImportDetail(supplyOrderNo) {
      return this.$axios.get(this.$api.supplierOrderOrder.importDetail, { params: { supplyOrderNo } });
    },
    // 获取商品列表
    getGoodsList(supplyOrderNo) {
      return this.$axios.get(this.$api.supplierOrderOrder.goodsList, { params: { supplyOrderNo } });
    },
    // 获取快递
    async getLogisticList() {
      const res = await this.$axios.get(this.$api.commonNew.getLogisticList, {
        params: {
          type: this.orderData.shippingType,
        },
      });
      this.logisticsCompanys = res.data || [];
    },
    async submit() {
      // 表单校验
      await this.checkFormRequired(this.dataList, this.orderData, this.type);
      // console.log(this.dataList);
      // 处理数据
      let api = this.type === 'edit' ? this.$api.supplierOrderOrder.importEdit : this.$api.supplierOrderOrder.deliver;
      let message = this.type === 'edit' ? '是否确认保存？' : '是否确认发货？';
      let params = this.type === 'edit'
        ? {
          importDeliverEditList: this.dataList,
        }
        : {
          supplyNo: this.orderData.supplyNo,
          supplyOrderDeliverList: this.dataList.map((item) => {
            return {
              logisticName: item.logisticName,
              logisticNo: item.logisticNo,
              memo: item.memo,
              memoImg: item.memoImg,
              supplyOrderGoodsDeliverList: item.relateGoods === 1
                ? item.packageRelateGoodsDetailList.map((goods) => {
                  return {
                    supplyOrderGoodsId: goods.supplyOrderGoodsId,
                    shipmentCount: goods.shipmentCount,
                  };
                })
                : null,
            };
          }),
        };

      // 判断是否有商品没有发完
      message = await this.hasUnaddedGoods(this.dataList, this.goodsList) || message;
      // 提交
      // console.log(params);
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.submitInterface(api, params);
      }).catch(() => {});
    },
    // 提交接口
    async submitInterface(api, params) {
      this.btnLoading = true;
      const { code, msg } = await this.$axios.post(api, params);
      this.btnLoading = false;
      if (code !== 0) {
        this.$alert(msg, '操作失败', {
          confirmButtonText: '确定',
          type: 'error',
        });
        return;
      }
      this.$message.success('操作成功！');
      this.handleClose();
      this.$emit('success');
    },
    // 表单校验
    checkFormRequired(dataList, orderData, type) {
      return new Promise((resolve) => {
        for (let value of dataList) {
          if (type === 'add' && orderData.shippingType === 1 && !value.logisticNo) {
            this.$message.error('请输入运单号');
            return;
          }

          if (type === 'add' && !value.logisticName) {
            this.$message.error('请选择物流公司');
            return;
          }

          if (value.relateGoods) {
            if (!value.packageRelateGoodsDetailList || !value.packageRelateGoodsDetailList.length) {
              this.$message.error('请添加商品');
              return;
            }
            for (let goods of value.packageRelateGoodsDetailList) {
              if (!goods.supplyOrderGoodsId) {
                this.$message.error('请选择商品');
                return;
              }
              if (!goods.shipmentCount) {
                this.$message.error('请输入商品数量');
                return;
              }
            }
          }
        }

        resolve();
      });
    },
    // 判断是否有未添加的商品
    hasUnaddedGoods(dataList, goodsList) {
      return new Promise((resolve) => {
        let obj = {};
        let isNotGoods;
        for (let item of dataList) {
          // 判断是否有未关联商品的，不用提示
          isNotGoods = item.relateGoods;
          for (let value of item.packageRelateGoodsDetailList) {
            if (obj[value.supplyOrderGoodsId]) {
              obj[value.supplyOrderGoodsId] += value.shipmentCount;
            } else {
              obj[value.supplyOrderGoodsId] = value.shipmentCount;
            }
          }
        }
        for (let item of goodsList) {
          let num = obj[item.supplyOrderGoodsId] || 0;
          if (item.num > (num + item.shipmentCount) && isNotGoods) {
            resolve('存在商品还未发完，是否确认发货？');
          }
        }
        resolve();
      });
    },

    // 添加商品
    addGoods(item) {
      item.packageRelateGoodsDetailList.push({
        supplyOrderGoodsId: '',
        shipmentCount: 0,
      });
    },
    // 选择商品
    changeGoods(value, i) {
      // 回填最大商品数量
      value.shipmentCount = this.getGoodsMaxNum(this.dataList, i, value.supplyOrderGoodsId);
    },
    // 修改商品数量
    changeGoodsNum(item, i) {
      if (!item.supplyOrderGoodsId) return;
      const max = this.getGoodsMaxNum(this.dataList, i, item.supplyOrderGoodsId);
      if (item.shipmentCount > max) {
        this.$message.error('商品总数量不能大于下单数');
        setTimeout(() => {
          item.shipmentCount = max;
        }, 50);
      }
    },
    // 获取当前商品最大可输入数量
    getGoodsMaxNum(list, index, orderId) {
      let num = 0;
      list.forEach((item, i) => {
        if (i === index) return;
        item.packageRelateGoodsDetailList.forEach((value) => {
          if (value.supplyOrderGoodsId !== orderId) return;
          num += value.shipmentCount;
        });
      });
      const find = this.goodsList.find((fin) => fin.supplyOrderGoodsId === orderId);
      if (!find) return 0;
      return find.num - num;
    },
    async deleteGoods(i, item) {
      await this.$confirm('是否确认删除关联商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
      item.packageRelateGoodsDetailList.splice(i, 1);
    },
    // 添加运单
    async operationWaybillOrder(type, index) {
      if (type === 'add') {
        this.dataList.push({
          ...this.orderData,
          logisticNo: '',
          logisticName: '',
          packageRelateGoodsDetailList: [{
            supplyOrderGoodsId: '',
            shipmentCount: 0,
          }],
        });
      } else if (type === 'delete') {
        await this.$confirm('是否确认删除运单数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        this.dataList.splice(index, 1);
      }
    },
    // 商品选择框禁用逻辑
    disabledGoodsFn(goodsitem, item) {
      // 售后状态 1、待商家处理 2、退款中 3、待用户处理 4、平台处理中 5、退款成功 6、退款失败 0、可申请售后
      // 判断指定状态
      let refundStatusReturn = [1, 2, 3, 4, 5].includes(goodsitem.refundStatus);
      // 判断是否选中
      let changeReturn = !!item.packageRelateGoodsDetailList.find(
        (find) => (find.supplyOrderGoodsId === goodsitem.supplyOrderGoodsId),
      );
      // 判断数量是否正确
      let numEnough = goodsitem.shipmentCount >= goodsitem.num;
      return refundStatusReturn || changeReturn || numEnough;
    },
    changeRelateGoods(e, item) {
      if (e === 1
      && item
      && item.packageRelateGoodsDetailList
      && item.packageRelateGoodsDetailList.length === 0) {
        item.packageRelateGoodsDetailList.push({
          supplyOrderGoodsId: '',
          shipmentCount: 0,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.deliver_order_dialog {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .order_number {
    font-size: 16px;
    font-weight: bold;
  }
}
.waybill_header {
  // display: flex;
  // justify-content: space-between;
  .waybill_header_box {
    margin-bottom: 10px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    margin-right: 15px;
    width: 30%;
    > p {
      margin-right: 5px;
      min-width: 60px;
      text-align: center;
    }
  }
}
.waybill_goods_select {
  margin-top: 20px;
}
.waybill_goods_box {
  margin-top: 10px;
}

// 下下拉框样式
.waybill_goods_select {
  .el-select-dropdown__item {
    height: 55px;
  }
}
</style>
