<template>
  <el-dialog
    v-if="showoff"
    :visible.sync="showoff"
    title="物流详情"
    custom-class="logistics-details-dialog"
    top="10vh"
    width="900px"
  >
    <div class="details-box">
      <div
        v-for="item in details"
        :key="item.key"
        class="item"
      >
        <div class="label">
          <b>{{ item.label }}：</b>
        </div>
        <div
          v-if="item.type !== 'image'"
          class="value"
        >
          {{ pages[item.key] }}
        </div>
        <div v-if="item.type === 'image' && pages[item.key]">
          <el-image
            v-for="value in pages[item.key].split(',')"
            :key="value"
            style="width: 60px; height: 60px"
            :src="value"
            fit="contain"
            :preview-src-list="pages[item.key].split(',')"
          />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="goods-box">
        <div class="title">
          运单中的商品
        </div>
        <div class="scroll-box">
          <el-scrollbar
            style="height: 400px;"
            :native="false"
          >
            <div
              v-for="(item, index) in goodsList"
              :key="index"
              class="goods-item"
            >
              <div style="margin-right: 10px;">
                <img
                  class="img"
                  :src="item.goodsImage"
                  alt=""
                />
              </div>
              <div class="content">
                <div class="name">
                  {{ item.goodsName }}
                </div>
                <div class="num">
                  x{{ item.shipmentCount }}
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>

      <div class="progress-box">
        <div class="title">
          物流进度
        </div>
        <el-scrollbar
          style="height: 400px;"
          :native="false"
        >
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in activities"
              :key="index"
              size="large"
              :timestamp="item.timestamp"
              :icon="item.icon"
              :color="item.color"
            >
              <div>{{ item.context }}</div>
            </el-timeline-item>
          </el-timeline>
        </el-scrollbar>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    this.details = [
      { label: '物流公司', key: 'logisticName' },
      { label: '运单号', key: 'logisticNo' },
      { label: '备注', key: 'memo' },
      { label: '备注图片', key: 'memoImg', type: 'image' },
    ];
    return {
      showoff: false,
      active: {},
      pages: {},
      goodsList: [],
      activities: [],
    };
  },
  methods: {
    show(row) {
      this.active = row;
      this.goodsList = [];
      this.activities = [];
      this.getData();
      this.showoff = true;
    },
    hide() {
      this.showoff = false;
    },
    async getData() {
      const params = { packageId: this.active.packageId };
      const res = await this.$axios.get(this.$api.delivery.detail, { params });
      this.pages = res.data || {};
      this.goodsList = this.pages.packageGoodsListVOList || [];

      const trackResp = this.pages.trackResp || {};
      const activities = trackResp.data || [];
      this.activities = activities.map((item, index) => ({
        timestamp: `操作时间：${item.ftime}`,
        context: item.context,
        icon: index === 0 ? 'el-icon-success' : 'el-icon-more',
        color: index === 0 ? '#409EFF' : 'el-icon-more',
      }));
    },
  },
};
</script>

<style lang="scss">
.logistics-details-dialog{
  .details-box{
    display: flex; margin-bottom: 20px;
    .item{display: flex; margin-right: 40px;}
  }
  .container{display: flex; padding: 10px; padding-right: 0; font-size: 12px; border: #ccc solid 1px;}
  .title{margin-bottom: 20px; font-size: 14px;}
  .goods-box{
    width: 50%;
    .img{width: 40px; height: 40px;}
    .name{
      max-height: 24px; margin-right: 5px; overflow: hidden; text-overflow: ellipsis;
      display: -webkit-box; -webkit-line-clamp: 2; line-clamp: 2; -webkit-box-orient: vertical;
    }
    .num{white-space: nowrap;}
    .scroll-box{padding-right: 10px; border-right: #ccc solid 1px;}
    .el-scrollbar__wrap{overflow-x: hidden;}
  }
  .content{display: flex; align-items: center; width: 330px;}
  .goods-item{display: flex; margin-bottom: 10px;}
  .progress-box{
    flex: 1; padding-left: 10px;
    .el-scrollbar__wrap{padding-left: 5px; padding-right: 5px; overflow-x: hidden;}
  }

  .el-timeline-item__content{line-height: 16px; font-size: 12px;}
}
</style>
