<template>
  <div>
    <SearchPanel
      flex
      show-btn
      size="mini"
      @getList="onSearch"
    >
      <div>
        <p>
          订货单号
        </p>
        <el-input
          v-model.trim="supplyNo"
          placeholder="请输入订货单号"
          size="mini"
          clearable
        />
      </div>
      <div>
        <p>
          商品ID
        </p>
        <el-input
          v-model.trim="goodsId"
          placeholder="请输入完整商品ID"
          size="mini"
          clearable
          type="number"
        />
      </div>

      <div>
        <p>
          商品名称
        </p>
        <el-input
          v-model.trim="goodsName"
          placeholder="请输入关键字"
          size="mini"
          clearable
        />
      </div>

      <div>
        <p>
          收件人名称
        </p>
        <el-input
          v-model.trim="receiverName"
          placeholder="请输入完整收件人名称"
          size="mini"
          clearable
        />
      </div>

      <div>
        <p>
          收件人手机号
        </p>
        <el-input
          v-model.trim="receiverMobile"
          placeholder="请输入收件人手机号"
          size="mini"
          clearable
        />
      </div>

      <div>
        <p>
          下单时间
        </p>
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          size="mini"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        />
      </div>
      <el-button
        slot="button-right"
        size="mini"
        @click="resetSearch"
      >
        重 置
      </el-button>
      <el-button
        slot="button-right"
        size="mini"
        @click="onExport"
      >
        导 出
      </el-button>
    </SearchPanel>
    <el-table
      v-loading="loading"
      :data="tableData"
      default-expand-all
      :cell-style="{
        'background-color': '#f5f7fa'
      }"
    >
      <el-table-column type="expand">
        <template slot-scope="{row}">
          <el-table
            class="interior-table"
            :data="row.supplyOrderGoodsVOList"
            size="small"
          >
            <el-table-column
              label="商品信息"
              width="300"
            >
              <template slot-scope="{row: goods}">
                <div class="goods-card">
                  <img :src="goods.goodsImg.split(',')[0]" />
                  <div class="content">
                    <p>{{ goods.goodsName }}</p>
                    <p class="sku">
                      ID:{{ goods.goodsId }}
                    </p>
                    <p class="sku">
                      规格：{{ goods.skuName }}
                    </p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="订货价"
              prop="purchasePrice"
            />
            <el-table-column
              label="数量"
              prop="purchaseCount"
            />
            <el-table-column
              label="商品金额"
              prop="purchaseAmount"
            />
            <el-table-column
              label="状态"
              prop="statusExplain"
            />
            <el-table-column
              label="售后状态"
              prop="refundStatusExplain"
            />
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        label="订货单号"
        prop="supplyNo"
      />
      <el-table-column
        label="订货单状态"
        prop="statusExplain"
      />
      <el-table-column
        label="订单金额"
        prop="purchaseAmount"
      />
      <el-table-column
        label="收件人"
        prop="receiverName"
      >
        <template #default="{row}">
          <span>{{ row.receiverName }}</span>&nbsp;
          <span>{{ row.receiverMobile }}</span>
        </template>
      </el-table-column>
      <el-table-column label="下单时间">
        <template slot-scope="{row}">
          <span>{{ formatTime(row.time) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="结算状态"
        prop="settlementStatusExplain"
      />
      <el-table-column
        label="操作"
        width="200"
      >
        <template slot-scope="{row}">
          <el-button
            size="mini"
            type="primary"
            @click="toDetail(row)"
          >
            查看详情
          </el-button>
          <el-button
            :disabled="!row?.canDeliver"
            size="mini"
            type="primary"
            @click="onDeliverGoods(row)"
          >
            发货
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 订单发货弹窗 -->
    <DeliverOrderDialog
      :id="id"
      v-model="showDeliverModel"
      type="add"
      @success="deliverSuccess"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import DeliverOrderDialog from './components/deliver-order-dialog.vue';
import { downloadFile } from '@/utils/download';

export default {
  components: {
    DeliverOrderDialog,
  },
  data() {
    return {
      isFirst: true,
      supplyNo: '', // 查询-订单编号
      goodsId: '', // 查询-商品ID
      goodsName: '', // 查询-商品名称
      receiverName: '', // 查询-收件人名称
      receiverMobile: '', // 查询-收件人手机号
      createBeginTime: '', // 查询-下单时间
      createEndTime: '',
      dateRange: [],
      loading: false,

      currentPage: 1, // 分页
      pageSize: 10,
      tableData: [],
      count: 0,
      // 发货弹窗数据
      showDeliverModel: false,
      id: '',
    };
  },
  activated() {
    if (this.isFirst) return;
    this.getSearchList();
  },
  mounted() {
    this.getSearchList();
  },
  methods: {
    onSearch() {
      // 修改搜索条件时把页码重置为1
      this.currentPage = 1;
      this.getSearchList();
    },
    getParams() {
      const [createBeginTime, createEndTime] = this.dateRange || [];
      return {
        supplyNo: this.supplyNo || undefined,
        goodsId: this.goodsId || undefined,
        goodsName: this.goodsName || undefined,
        receiverName: this.receiverName || undefined,
        receiverMobile: this.receiverMobile || undefined,
        createBeginTime,
        createEndTime,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        status: 4,
        deliveryStatus: 1,
      };
    },
    async getSearchList() {
      const params = this.getParams();
      this.loading = true;
      const { code, data } = await this.$axios.post(this.$api.supplierOrderOrder.page, params);
      this.loading = false;
      this.isFirst = false;
      if (code !== 0) return;
      this.tableData = data.records;
      this.count = +data.total;
    },
    resetSearch() {
      Object.assign(this.$data, this.$options.data());
      this.getSearchList();
    },
    onExport() {
      this.$confirm('是否确认导出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const loading = this.$loading();
        const params = this.getParams();
        const blob = await this.$axios.post(this.$api.supplierOrderOrder.export, params, {
          responseType: 'blob',
        });
        loading.close();
        const date = dayjs(Date.now()).format('YYYY-MM-DD_HH.mm.ss');
        downloadFile(blob, `订货单待发货_${date}.xls`);
      }).catch(() => {
      });
    },
    formatTime(time) {
      return time ? dayjs(+time).format('YYYY-MM-DD HH:mm:ss') : '-';
    },
    toDetail({ id }) {
      this.$router.push({
        path: '/supplyChain/purchaseOrder/detail',
        query: {
          orderId: id,
        },
      });
    },
    // 发货
    onDeliverGoods({ id }) {
      this.id = id;
      this.showDeliverModel = true;
    },
    // 发货成功
    deliverSuccess() {
      this.getSearchList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getSearchList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSearchList();
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-card {
  min-width: 10px;
  width: 100%;
  display: flex;
  img {
    margin-top: 8px;
    width: 60px;
    height: 60px;
  }
  .content {
    padding: 0 12px;
    line-height: 28px;
    .sku{
      color: #736E6E;
    }
  }
}
.interior-table {
  width: calc(100% - 80px);
  margin-left: auto;
}
</style>
